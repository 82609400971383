import { sortOptions } from "../_constants";
import { formatDateForApi, formatDateForApiInstant } from "./date";

export function createParams(page, pageSize, filters, sorter, timeFields) {
  // page - 1 bc paginator starts from 1, not 0
  const params = {};
  if (page) {
    params.pageNumber = page - 1;
  }
  if (pageSize) {
    params.pageSize = pageSize;
  }
  if (sorter && sorter.field && sorter.order) {
    params['sortBy'] = sorter.field;
    params['sortOrder'] = sortOptions[sorter.order];
  }

  if (filters) {
    for (const [key, value] of Object.entries(filters)) {
      if (!value) {
        continue;
      }
      if (timeFields && timeFields.includes(key)) {
        if (key === 'leafCertificateValidTo') {
          if (value[0][0]) {
            params['validFrom'] = formatDateForApiInstant(value[0][0]);
          }
          if (value[0][1]) {
            params['validTo'] = formatDateForApiInstant(value[0][1]);
          }
        } else {
          if (value[0]) {
            params['start'] = formatDateForApi(value[0]);
          }
          if (value[1]) {
            params['end'] = formatDateForApi(value[1]);
          }
        }

      } else {
        params[key] = value.join(',');
      }
    }
  }

  return params;
}
