import {get, post, createParams, patch, del, put} from "../_helpers";

function getDevices({page, pageSize, filters, sorter}, auth0Token) {
    const params = createParams(page, pageSize, filters, sorter, ['leafCertificateValidTo']);
    return get({path: 'devices', parameters: params}, auth0Token);
}

function getDeviceById(id, auth0Token) {
    return get({path: `devices/${id}`}, auth0Token);
}

function getCertificatesForDevice(id, auth0Token) {
    return get({path: `devices/${id}/certificates`}, auth0Token);
}

function executeAction(params, payload, auth0Token) {
    return post({
        path: `remote-action/trigger-action?${params}`,
        body: payload,
    }, auth0Token);
}

function updateDevicesState(params, payload, auth0Token) {
    return patch({
        path: `state-settings/update-many?${params}`,
        body: payload,
    }, auth0Token);
}

function togglePortStatus(chargeboxId, evseNumberAndPortIds, multiEvses, status, message, auth0Token) {
    return post({
        path: `devices/toggle-port-status/${chargeboxId}`,
        body: {
            evsePortIds: evseNumberAndPortIds,
            availabilityType: status,
            message: message,
            multiEvses: multiEvses
        }
    }, auth0Token);
}

function exportDevices({filters}, auth0Token) {
    const params = createParams(null, null, filters, null, null);
    return get({path: 'devices/export', parameters: params}, auth0Token);
}

function getTags(auth0Token) {
    return get({path: 'devices/tags'}, auth0Token);
}

function addTag(id, tag, auth0Token) {
    tag = encodeURIComponent(tag);
    return put({
        path: `devices/${id}/tags/${tag}`
    }, auth0Token);
}

function deleteTag(id, tag, auth0Token) {
    tag = encodeURIComponent(tag);
    return del({
        path: `devices/${id}/tags/${tag}`
    }, auth0Token);
}

function getConnectorTypes(auth0Token) {
  return get({path: 'devices/connector-types'}, auth0Token);
}

export const deviceService = {
  getDevices,
  getDeviceById,
  getCertificatesForDevice,
  executeAction,
  updateDevicesState,
  togglePortStatus,
  exportDevices,
  getTags,
  deleteTag,
  addTag,
  getConnectorTypes
};
