import moment from 'moment-timezone';

export function formatDateForApi(date) {
  return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
}

export function formatDateForApiInstant(date) {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss[Z]')
} 

export function formatFullYearMonths(value, includeMilliseconds = true) {
  const format = includeMilliseconds ? 'MM/DD/YYYY h:mm:ss.SSS A' : 'MM/DD/YYYY h:mm:ss A';
  return moment.utc(value).tz(getBrowserTimezone()).format(format);
}

export function formatTime(value) {
  return moment.utc(value).tz(getBrowserTimezone()).format('h:mm:ss A');
}

export function getBrowserTimezone() {
  return moment.tz.guess();
}

export const dateDiff = (a, b) => {
  const dateA = a
            ? moment(a).valueOf()
            : 0;
          const dateB = b
            ? moment(b).valueOf()
            : 0;
          return dateA - dateB;
}