import {Routes, Route} from "react-router-dom";
import NoMatch from "_pages/NoMatch";
import Logs from "_pages/Logs";
import Devices from "_pages/Devices";
import ChargingGroups from "_pages/ChargingGroups";
import ChargingGroupDetail from "_pages/ChargingGroups/ChargingGroupDetailController";
import Device from "_pages/Device";
import Login from "_pages/Login";

import RegisteredApp from "_partial/RegisteredApp";
import Home from "../_pages/Home";
import AlertsController from "../_pages/Alerts";
import Locations from "../_pages/Locations";

import Location from "../_pages/Location";

const CustomRoutes = () => {
    return (
        <Routes>
            {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
            <Route element={<RegisteredApp/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="devices" element={<Devices/>}/>
                <Route path="locations" element={<Locations/>}/>
                <Route path="locations/:locationId" element={<Location/>}/>
                <Route path="charging-groups" element={<ChargingGroups/>}/>
                <Route path="charging-groups/:chargingGroupId" element={<ChargingGroupDetail/>}/>
                <Route path="devices/:deviceId" element={<Device/>}/>
                <Route path="logs" element={<Logs/>}/>
                {/* Alerts temporarily commented out until they are implemented 6/2/2022 */}
                <Route path="alerts" element={<AlertsController/>}/>
                {/*<Route path="alerts/:alertName" element={<AlertDetailController />} />*/}
                {/*<Route path="settings" element={<Settings />} />*/}
            </Route>
            <Route path="login" element={<Login/>}/>
            {/* Using path="*"" means "match anything", so this route
            acts like a catch-all for URLs that we don't have explicit
            routes for. */}
            <Route path="*" element={<NoMatch/>}/>
        </Routes>
    );
};

export default CustomRoutes;
