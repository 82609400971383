import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    executeDeviceAction,
    exportDevicesDataAction,
    getCertificatesForDevice,
    getDeviceDataByIdAction,
    getDevicesDataAction,
    getDevicesTagsDataAction,
    updateDevicesStateAction,
    getDevicesConnectorTypesDataAction
} from '_store/devices';
import {
    defaultTablePageSize,
    deviceDetailActions,
    devicesActions,
    remoteActionEventTypes,
    remoteActionResetTypes,
} from '_constants';
import { useApi } from "./useApi.hook";
import { getEvseMessagesAction } from '_store/evses';
import { getDeviceChargingGroupDataAction, getDeviceChargingGroupsDataAction, } from '_store/deviceChargingGroups';
import { deviceService, evsesService } from "../_services";
import { errorNotification, openNotification } from "../_components/Notification";
import { addColumnFilter } from '_pages/Devices/columnsFilter';
import { allCols } from "../_constants/devicesTableColConstants";
import { usePermissions } from "./permissions.hook";
import * as permConsts from "../_constants/permissionConstants";

export const useRemoteActions = (device = null) => {
    const dispatch = useDispatch();
    const [selectedAction, setSelectedAction] = useState(null);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [selectedDeviceRows, setSelectedDeviceRows] = useState([]); // only matters for state settings
    const [deselectedRows, setDeselectedRows] = useState([]);
    const [getAll, setGetAll] = useState(false);
    const { auth0Token } = useApi();
    const permissions = usePermissions();

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowIds([...selectedRowKeys]);
        setSelectedDeviceRows(selectedRows.map(row => ({ chargeboxId: row.chargeboxId, partyId: row.partyId })));
    };

    const onSelectAll = (selected) => {
        setGetAll(selected);
        setDeselectedRows([]);
    }

    const onDeselect = (record, selected) => {
        if (getAll) {
            if (!selected && !deselectedRows.includes(record.chargeboxId)) {
                setDeselectedRows([...deselectedRows, record.chargeboxId]);
            } else {
                setDeselectedRows(deselectedRows.filter(row => row !== record.chargeboxId));
            }
        }
    }

    const clearSelection = () => {
        setSelectedAction(null);
        setSelectedRowIds([]);
        setGetAll(false);
        setDeselectedRows([]);
    }

    const onClickAction = (action) => () => {
        setSelectedAction(action);
    }

    const onClickConfirm = (actionName) => (data) => {
        const finalData = { ...data, getAll, deselectedRows };
        switch (actionName) {
            case 'update_firmware':
                updateFirmware(finalData);
                break;
            case 'hard_reset':
                hardReset(finalData);
                break;
            case 'soft_reset':
                softReset(finalData);
                break;
            case 'update_state':
                updateState(finalData);
                break;
            case 'trigger_message_request':
                triggerMessageRequest(finalData);
                break;
            case 'get_diagnostics_request':
                getDiagnosticsRequest(finalData);
                break;
            default:
                console.log(`Invalid action ${actionName}, nothing will be done`);
        }

        clearSelection();
    }

    const updateFirmware = (data) => {
        dispatch(executeDeviceAction({
            deviceIds: device && device.chargeboxId ? [device.chargeboxId] : selectedRowIds,
            resetType: null,
            eventType: remoteActionEventTypes.UPDATE_FIRMWARE_REQUEST,
            ...data,
            auth0Token
        }));
    };

    const hardReset = (data) => {
        dispatch(executeDeviceAction({
            deviceIds: device && device.chargeboxId ? [device.chargeboxId] : selectedRowIds,
            resetType: remoteActionResetTypes.HARD,
            eventType: remoteActionEventTypes.RESET_REQUEST,
            ...data,
            auth0Token
        }));
    };

    // this is only used for MULTIPLE states
    // individual state is updated elsewhere
    const updateState = (data) => {
        dispatch(updateDevicesStateAction({
            chargeboxPartyIds: selectedDeviceRows,
            ...data,
            auth0Token
        }));
    };

    const softReset = (data) => {
        dispatch(executeDeviceAction({
            deviceIds: device && device.chargeboxId ? [device.chargeboxId] : selectedRowIds,
            resetType: remoteActionResetTypes.SOFT,
            eventType: remoteActionEventTypes.RESET_REQUEST,
            ...data,
            auth0Token
        }));
    };

    const triggerMessageRequest = (data) => {
        dispatch(executeDeviceAction({
            deviceIds: device && device.chargeboxId ? [device.chargeboxId] : selectedRowIds,
            eventType: remoteActionEventTypes.TRIGGER_MESSAGE_REQUEST,
            ...data,
            auth0Token
        }));
    };

    const getDiagnosticsRequest = (data) => {
        dispatch(executeDeviceAction({
            deviceIds: device && device.chargeboxId ? [device.chargeboxId] : selectedRowIds,
            eventType: remoteActionEventTypes.GET_DIAGNOSTICS_REQUEST,
            ...data,
            auth0Token
        }));
    };

    const filterPermittedActions = (actions) => {
        return actions.filter((action) => {
            const actionName = action.name;
            switch (actionName) {
                case 'update_firmware':
                    return permissions.checkPermission(permConsts.components.devices, permConsts.actions.updateFirmware);
                case 'hard_reset':
                    return permissions.checkPermission(permConsts.components.devices, permConsts.actions.reset);
                case 'soft_reset':
                    return permissions.checkPermission(permConsts.components.devices, permConsts.actions.reset);
                case 'trigger_message_request':
                    return permissions.checkPermission(permConsts.components.devices, permConsts.actions.triggerMessageRequest);
                case 'get_diagnostics_request':
                    return permissions.checkPermission(permConsts.components.devices, permConsts.actions.getDiagnosticsRequest);
                case `update_state`:
                    return permissions.checkMultiplePermissions([
                        { component: permConsts.components.devices, action: permConsts.actions.deviceStateFreevend },
                        { component: permConsts.components.devices, action: permConsts.actions.deviceStateAvailable },
                        { component: permConsts.components.devices, action: permConsts.actions.deviceStatePncEnabled },
                        {
                            component: permConsts.components.devices,
                            action: permConsts.actions.deviceStateMaintenanceMode
                        },
                    ]);
                default:
                    return true;
            }
        });
    }

    return {
        selectedRowIds,
        selectedAction,
        onSelectChange,
        clearSelection,
        onClickConfirm,
        onClickAction,
        onDeselect,
        onSelectAll,
        triggerMessageRequest,
        actions: filterPermittedActions(device && device.chargeboxId ? deviceDetailActions : devicesActions),
    }
}

export const useDevices = (columns) => {
    const {
        data,
        loading,
        pagination,
        sorter,
        tags,
        tagsLoading,
        connectorTypes,
        connectorTypesLoading
    } = useSelector(state => state.devicesReducer);
    let columnsWithFilter = [];

    const getCurrentFilters = () => {
        return JSON.parse(localStorage.getItem('currentFilters'))
    }

    const setFiltersOnColumns = (storedFilters) => {
        const filtersAreEmpty = storedFilters && Object.values(storedFilters).every(x => x === null || x === '')  
        if (storedFilters !== null &&
            !filtersAreEmpty && columnsWithFilter.length > 0) {
            columnsWithFilter.forEach((column) => {
                Object.keys(storedFilters).forEach((key) => {
                    if (column.dataIndex === key && storedFilters[key] !== null) {
                        column['filteredValue'] = storedFilters[key]
                    }
                });
            });
        }
    }
    
    // if the user edited columns before, read from local storage to retrieve his/her edited columns
    if (localStorage.getItem('selectedColumns') == null) {
        columnsWithFilter = addColumnFilter(columns, tags, tagsLoading, connectorTypes, connectorTypesLoading);
        const storedFilters = getCurrentFilters()
        setFiltersOnColumns(storedFilters)
    } else {
        const selectedColumnsWithoutRendering = JSON.parse(localStorage.getItem('selectedColumns'));
        let selectedColumnsWithRendering = [];
        // localStorage can only save the partial column configuration without any renders
        // need to find the corresponding rendered columns inside allCols and push them into the array
        selectedColumnsWithoutRendering.forEach((column, index) => {
            selectedColumnsWithRendering.push(...allCols.filter((col, i) => {
                return column.dataIndex === col.dataIndex
            }));
        });
        columnsWithFilter = addColumnFilter(selectedColumnsWithRendering, tags, tagsLoading, connectorTypes, connectorTypesLoading);
        const storedFilters = getCurrentFilters()
        setFiltersOnColumns(storedFilters)
    }
    const { auth0Token, userEmail, loading: tokenLoading } = useApi();
    const remoteActionsData = useRemoteActions();
    const dispatch = useDispatch();
    const [currentFilters, setCurrentFilters] = useState(localStorage.getItem('currentFilters'));


    useEffect(() => {
        const storedFilters = getCurrentFilters()
        if (!tokenLoading && auth0Token && dispatch && userEmail) {
            dispatch(getDevicesData(null, storedFilters, sorter, auth0Token, userEmail));
            dispatch(getDevicesTagsDataAction({ auth0Token }))
            dispatch(getDevicesConnectorTypesDataAction({ auth0Token }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenLoading, auth0Token]);
   
    const getCurrentAuth = () => {
        const auth0Token = JSON.parse(localStorage.getItem('auth0Token'))
        const userEmail = JSON.parse(localStorage.getItem('userEmail'))
        return { auth0Token, userEmail }
    }

    const storeFilters = (filters) => {
        localStorage.setItem('currentFilters', JSON.stringify(filters));
        if (!tokenLoading) {
            localStorage.setItem('auth0Token', JSON.stringify(auth0Token));
            localStorage.setItem('userEmail', JSON.stringify(userEmail));
        }
    }

    useEffect(() => {
        const { auth0Token, userEmail } = getCurrentAuth()
        const storedFilters = getCurrentFilters()
        setFiltersOnColumns(storedFilters)
        setCurrentFilters(storedFilters)
        if (storedFilters && auth0Token) {
            dispatch(getDevicesData(null, storedFilters, sorter, auth0Token, userEmail));
            dispatch(getDevicesTagsDataAction({ auth0Token }))
            dispatch(getDevicesConnectorTypesDataAction({ auth0Token }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (pagination, filters, sorter) => {
        storeFilters(filters);
        setCurrentFilters(filters)
        dispatch(getDevicesData(pagination, filters, sorter, auth0Token, userEmail));
    }

    const refreshDevices = () => {
        dispatch(getDevicesData(null, {}, sorter, auth0Token, userEmail));
    }

    const getDevicesData = (pagination, filters, sorter, auth0Token, userEmail) => {
        const page = pagination ? pagination.current : 1;
        const pageSize = pagination ? pagination.pageSize : defaultTablePageSize.devices;

        if (userEmail && userEmail.toLowerCase().includes('vendor')) {
            const userCreatedFilter = { userCreated: [userEmail] };
            filters = { ...filters, ...userCreatedFilter };
        }

        return getDevicesDataAction({
            page: page,
            pageSize: pageSize,
            filters: filters,
            sorter: sorter,
            auth0Token
        });
    };

    const onClickExport = () => {
        dispatch(exportDevicesDataAction({
            filters: currentFilters,
            auth0Token
        }))
    }

    return {
        data,
        loading,
        tagsLoading,
        connectorTypesLoading,
        pagination,
        onChange,
        currentFilters,
        columns: columnsWithFilter,
        refreshDevices,
        ...remoteActionsData,
        onClickExport,
    };
};

// retrieves all basic device data
export const useOneDevice = (id) => {
    const { device, loading } = useSelector(state => state.devicesReducer);
    const { auth0Token, loading: tokenLoading } = useApi();

    const dispatch = useDispatch();


    useEffect(() => {
        if (!tokenLoading && auth0Token && dispatch) {
            dispatch(getDeviceDataByIdAction({ id, auth0Token }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenLoading, auth0Token]);

    const togglePortStatus = (evseNumberAndPortIds, status, message = "") => {
        deviceService.togglePortStatus(device?.chargeboxId,
            evseNumberAndPortIds.map(evsePortString => JSON.parse(evsePortString)), device?.evses?.length > 1, status, message, auth0Token)
            .then(() => openNotification("Successfully changed port status", "Changes will appear in a few minutes"))
            .catch(err => errorNotification("Error changing port status", err));
    }

    const getDevice = () => {
        dispatch(getDeviceDataByIdAction({ id, auth0Token }));
    }

    return {
        device,
        loading,
        togglePortStatus,
        getDevice
    };
};

export const useGetCertificatesForDevice = (device) => {
    const { deviceCertificates, loading } = useSelector(state => state.devicesReducer);
    const { auth0Token, loading: tokenLoading } = useApi();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!tokenLoading && auth0Token && dispatch && device && device.chargeboxId) {
            dispatch(getCertificatesForDevice({ chargeboxId: device?.chargeboxId, auth0Token }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenLoading, auth0Token, dispatch, device]);

    const getCertificates = () => {
        dispatch(getCertificatesForDevice({ chargeboxId: device?.chargeboxId, auth0Token }));
    }

    return {
        deviceCertificates,
        loading,
        getCertificates
    };
};


export const useDeviceTag = (device) => {
    const { auth0Token, loading: tagTokenLoading } = useApi();

    const deleteTag = async (tag) => {
        await deviceService.deleteTag(device.chargeboxId, tag, auth0Token)
            .then(() => openNotification("Successfully deleted tag " + tag))
            .catch(err => errorNotification("Failed to delete tag " + tag, err));
    }

    const getTags = async () => {
        const allTags = []
        await deviceService.getTags(auth0Token)
            .then((value) => {
                value["tags"].forEach((tag) => allTags.push(tag))
            })
            .catch(err => errorNotification("Failed to get all tags ", err));
        return allTags
    }

    const addTagToDevice = async (tag) => {
        await deviceService.addTag(device.chargeboxId, tag, auth0Token)
            .then(() => openNotification("Successfully added tag " + tag.toUpperCase()))
            .catch(err => errorNotification("Failed to add tag " + tag.toUpperCase(), err));
    }

    return {
        deleteTag,
        getTags,
        addTagToDevice,
        tagTokenLoading
    }
}

// currently used for device message 8/8/2022
export const useDeviceEvse = (device) => {
    const { evse, evseLoading, evseUpdating, evsePagination } = useSelector(state => state.evsesReducer);
    const { auth0Token, loading: tokenLoading } = useApi();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!tokenLoading && auth0Token && dispatch && device && device.chargeboxId) {
            dispatch(getEvseMessagesAction({ chargeboxId: device?.chargeboxId, page: 0, auth0Token }));
        }
    }, [tokenLoading, auth0Token, dispatch, device]);

    const getEvseMessages = () => {
        dispatch(getEvseMessagesAction({ chargeboxId: device?.chargeboxId, page: 0, auth0Token }));
    }

    const onChange = (pagination) => {
        dispatch(getEvseMessagesAction({
            chargeboxId: device?.chargeboxId,
            page: pagination ? pagination.current - 1 : 0,
            auth0Token
        }));
    }

    const handleAddEvseMessage = (payload) => {
        evsesService.addEvseMessage(
            device?.chargeboxId,
            payload,
            auth0Token
        ).then(getEvseMessages).catch((err) => {
            errorNotification("Message failed to save", err);
        });
    }

    return {
        evse,
        evseLoading,
        evseUpdating,
        evsePagination,
        onChange,
        handleAddEvseMessage,
        getEvseMessages
    };
}

export const useDeviceChargingGroups = (device) => {
    const {
        data,
        deviceChargingGroup,
        loading: chargingGroupsLoading,
        pagination,
        filters,
        sorter,
    } = useSelector(state => state.deviceChargingGroupsReducer);
    const { evsesUpdating } = useSelector(state => state.evsesReducer);

    const {
        auth0Token,
        loading: tokenLoading,
    } = useApi();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!tokenLoading && auth0Token && device && device.chargeboxId) {
            dispatch(getDeviceChargingGroupDataAction({
                deviceId: device.chargeboxId,
                auth0Token,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth0Token, device]);

    useEffect(() => {
        getChargingGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evsesUpdating]);

    const getChargingGroup = () => {
        if (dispatch && device && device.chargeboxId && evsesUpdating === false && auth0Token) {
            dispatch(getDeviceChargingGroupDataAction({
                deviceId: device.chargeboxId,
                auth0Token,
            }));
        }
    }

    // deprecated, keeping around just in case though
    const onChargingGroupTableChange = (pagination, filters, sorter, extra) => {
        dispatch(getDeviceChargingGroupsDataAction({
            page: pagination.current,
            pageSize: pagination.pageSize,
            filters,
            sorter,
            auth0Token,
        }));
    };

    return {
        deviceChargingGroup,
        chargingGroups: data,
        chargingGroupsLoading,
        pagination,
        filters,
        sorter,
        onChargingGroupTableChange,
        getChargingGroup
    };
};
